import React from "react";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getOfficeAcceseriosByIdApi } from "src/service";
import { useLocation } from "react-router-dom";
import {
  Card,
  Table,
  CardContent,
  Button,
  CardHeader,
  Stack,
  Typography,
  TableBody,
  TableContainer,
  Box,
  TableRow,
  TableCell,
  Rating,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../components/table";
import Scrollbar from "../../components/scrollbar";
import constant from "src/Constant";
// const TABLE_HEAD = [
//   {
//     id: "Device Type/Device Name",
//     label: "Device Type/Device Name",
//     align: "left",
//   },
//   {
//     id: "Assign To/Assign By/Submit To/Submit By",
//     label: "Assign To/Assign By/Submit To/Submit By",
//     align: "left",
//   },
//   { id: "Front Images", label: "Front Images", align: "left" },
//   { id: "Back Images", label: "Back Images", align: "left" },
//   { id: "Description", label: "Description", align: "left" },
//   { id: "Date", label: "Date", align: "left" },
//   { id: "Rating", label: "Rating", align: "left" },
// ];
const OfficeAccessoriesHistory = () => {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onChangePageButton,
  } = useTable();
  const { id } = useParams();
  const navigate = useNavigate();
  const [officeAccessoriesData, setofficeAccessoriesData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isAddedView = searchParams.get("view") === "added";
  const [isNotFound, setIsNotFound] = useState(false);

  console.log("isAddedView ::::", isAddedView);
  const TABLE_HEAD = [
    {
      id: "Device Type/Device Name",
      label: "Device Type/Device Name",
      align: "left",
    },
    {
      id: "Assign To/Assign By/Submit To/Submit By",
      label: isAddedView ? "Added By" : "Assign To/Assign By/Submit To/Submit By",
      align: "left",
    },
    { id: "Front Images", label: "Front Images", align: "left" },
    { id: "Back Images", label: "Back Images", align: "left" },
    { id: "Description", label: "Description", align: "left" },
    { id: "Date", label: "Date", align: "left" },
    { id: "Rating", label: "Rating", align: "left" },
  ];

  useEffect(() => {
    getOfficeAcceseriosById();
  }, []);

  const getOfficeAcceseriosById = async () => {
    const res = await getOfficeAcceseriosByIdApi(id);
    console.log("   res ::::", res);
    if (res?.data?.status && Array.isArray(res.data.data)) {
      setofficeAccessoriesData(res.data.data);
    } else {
      setofficeAccessoriesData([]);
    }
  };

  const renderAssignDetails = (row) => {
    if (isAddedView) {
      return (
        <>
          <Typography variant="body2" noWrap>
            Assign By: {row?.AssignedBy?.nameEnglish || "N/A"}
          </Typography>
        </>
      );
    }
    return (
      <>
        {row?.assign_to ? (
          <>
            <Typography variant="body2" noWrap>
              Assign To: {row?.AssignedTo?.nameEnglish || "N/A"}
            </Typography>
            <Typography variant="body2" noWrap>
              Assign By: {row?.AssignedBy?.nameEnglish || "N/A"}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2" noWrap>
              Submit By: {row?.SubmittedBy?.nameEnglish || "N/A"}
            </Typography>
            <Typography variant="body2" noWrap>
              Submit To: {row?.SubmittedTo?.nameEnglish || "N/A"}
            </Typography>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          OfficeAccessoriesHistory
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(PATH_DASHBOARD?.general?.officeaccessories)}
        >
          Back
        </Button>
      </Box>
      <Card>
        <CardContent sx={{ py: 2, px: 2 }}>
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {officeAccessoriesData.map((row) => (
                    <TableRow hover key={row.id}>
                      <TableCell>
                        <Typography variant="body2" noWrap>
                          {row?.accessory?.types} /{" "}
                          {row?.accessory?.company_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TableCell>
                          <Typography variant="body2" noWrap>
                            {/* {row?.assign_to ? ( // Check if the accessory is assigned
                              <>
                                Assign To: {row?.AssignedTo?.nameEnglish || "N/A"}
                                <br />
                                Assign By: {row?.AssignedBy?.nameEnglish || "N/A"}
                                <br />
                              </>
                            ) : ( // Otherwise, show submission details
                              <>
                                Submit By: {row?.SubmittedBy?.nameEnglish || "N/A"}
                                <br />
                                Submit To: {row?.SubmittedTo?.nameEnglish || "N/A"}
                                <br />
                              </>
                            )} */}
                            {renderAssignDetails(row)}
                          </Typography>
                        </TableCell>
                      </TableCell>

                      <TableCell>
                        {row?.front_image && (
                          <img
                            src={`${constant.appBaseUrl}/users/accesories/${row?.front_image}`}
                            alt="Assign"
                            width="100"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {row?.back_image && (
                          <img
                            src={`${constant.appBaseUrl}/users/accesories/${row?.back_image}`}
                            alt="Submit"
                            width="100"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap>
                          {row?.accessory?.assign_to !== null
                            ? row?.accessory?.assign_discription
                            : row?.accessory?.submited_discription}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap>
                          {row?.updated_at
                            ? new Date(
                              row?.updated_at
                            ).toLocaleString()
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap>
                          {row?.accessory?.rating}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* {officeAccessoriesData.map((row) => (
                                        <TableRow hover key={row.id}>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                        noWrap
                                                    >
                                                        {row?.id}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                        noWrap
                                                    >
                                                        {row?.type}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                        noWrap
                                                    >
                                                        {row?.start_id}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                        noWrap
                                                    >
                                                        {row?.end_id}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                        noWrap
                                                    >
                                                        {row?.staff_id}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                        noWrap
                                                    >
                                                        {row?.staff_name}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                        noWrap
                                                    >
                                                        {new Date(row?.createdAt).toLocaleTimeString(
                                                            "en-US",
                                                            {
                                                                year: "numeric",
                                                                month: "short",
                                                                day: "numeric",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                hour12: true,
                                                            }
                                                        )}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))} */}
                  {/* Display "No Data" message if applicable */}
                  {/* <TableNoData isNotFound={isNotFound} /> */}
                </TableBody>
              </Table>
            </Scrollbar>
            {/* Show loading indicator if officeAccessoriesData is empty */}
            {/* {!officeAccessoriesData.length && !isNotFound && (
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <LoadingScreenSmall />
                            </Box>
                        )} */}
          </TableContainer>
        </CardContent>

        <TablePaginationCustom
          // count={total}
          page={page}
          onChangePageButton={onChangePageButton}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
};

export default OfficeAccessoriesHistory;
