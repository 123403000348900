const constant = {
  // appBaseUrl: "https://mobiledev.jainshadimilan.com",
  appBaseUrl: "https://mobile.jainshadimilan.com",
  // appBaseUrl: "http://localhost:7200",
  DemoImageURl: "https://dev.jainshadimilan.com/profile.php?id=",
  DemoImageHinduURl: "https://mobile.hindushadiconnect.com/app/users/",
  updateBiodata: false, //this key should always be false
  scannerUrl: "https://scanner.cityjobsindia.com",
  // scannerUrl: "http://127.0.0.1:5001",
};
export default constant;
export const currentAppVersion = "0.3.86";
export const currentStaffAppVersion = "s.1.0.8";
